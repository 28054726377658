import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Dashboard.css';
import parse from 'html-react-parser';
import '../components/Layout.css';

const Dashboard = () => {
  const [blogs, setBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [limit] = useState(9);
  const [searchQuery, setSearchQuery] = useState('');
  const [language, setLanguage] = useState('english');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedBlogId, setSelectedBlogId] = useState(null);
  const [publishDate, setPublishDate] = useState('');

  const navigate = useNavigate();
  const backendURL = process.env.REACT_APP_BackendURL;

  // Define fetchBlogs first
  const fetchBlogs = async () => {
    try {
      const response = await fetch(
        `${backendURL}/api/blogs/allblogs?page=${page}&limit=${limit}&search=${searchQuery}&language=${language}`
      );

      if (response.ok) {
        const result = await response.json();
        setBlogs(result.blogs);
        setTotalPages(result.totalPages);
      } else {
        console.error('Failed to fetch blogs:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, [page, limit, searchQuery, language]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/');
  };

  // Open date picker when publish button is clicked
  const handlePublishClick = (id, currentStatus) => {
    if (!currentStatus) {
      // If currently unpublished, show the date picker
      setSelectedBlogId(id);
      setShowDatePicker(true);
      setPublishDate(''); // Reset date
    } else {
      // If already published, just unpublish directly
      handleTogglePublish(id, currentStatus);
    }
  };

  const handleTogglePublish = async (id, currentStatus, date = null) => {
    try {
      // If date provided, ensure it's in ISO format
      const requestBody = date ? { publishDate: new Date(date).toISOString() } : {};
      
      const response = await fetch(`${backendURL}/api/blogs/blogs/${id}/publish`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
  
      if (response.ok) {
        const updatedBlog = await response.json();
        setBlogs((prevBlogs) =>
          prevBlogs.map((blog) =>
            blog._id === id ? { ...blog, publish: !currentStatus } : blog
          )
        );
  
        // Close date picker if open
        setShowDatePicker(false);
        setSelectedBlogId(null);
  
        // Refetch blogs to refresh the table
        fetchBlogs();
      } else {
        console.error('Failed to toggle publish status:', response.statusText);
      }
    } catch (error) {
      console.error('Error toggling publish status:', error);
    }
  };
  const handleDateSubmit = () => {
    if (selectedBlogId) {
      // If date is empty, use current date
      const dateToUse = publishDate || new Date().toISOString().split('T')[0];
      handleTogglePublish(selectedBlogId, false, dateToUse);
    }
  };

  const handleAddBlog = () => {
    navigate('/add-blog');
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this blog?');
    if (confirmDelete) {
      try {
        const response = await fetch(`${backendURL}/api/blogs/blogs/${id}`, {
          method: 'DELETE',
        });
        if (!response.ok) throw new Error('Failed to delete blog');
        alert('Blog deleted successfully');
        navigate('/bloglist');
      } catch (err) {
        console.error('Error deleting blog:', err.message);
      }
    }
  };


const formatDate = (dateString) => {
  if (!dateString) return 'Not scheduled';
  const date = new Date(dateString);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`;
};

  return (
    <div>
      <div className="text-center">
        <div className="p-4 pt-5">
          <div className="card shadow">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Blogs</h5>
              <a href="# " onClick={handleAddBlog} className="btn btn-primary">
                Add Blog
              </a>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-4">
                  <input
                    className="form-control me-2"
                    type="text"
                    aria-label="Search"
                    placeholder="Search something..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div className="col-4">
                  <select
                    className="form-select"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="english">English</option>
                    <option value="arabic">Arabic</option>
                  </select>
                </div>
              </div>

              {/* Date Picker Modal */}
              {showDatePicker && (
                <div className="modal show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">Select Publish Date</h5>
                        <button 
                          type="button" 
                          className="btn-close" 
                          onClick={() => setShowDatePicker(false)}>
                        </button>
                      </div>
                      <div className="modal-body">
  <div className="mb-3">
    <label className="form-label">Publish Date and Time</label>
    <input 
      type="datetime-local" 
      className="form-control"
      value={publishDate}
      onChange={(e) => setPublishDate(e.target.value)}
    />
    <small className="text-muted">Leave empty for immediate publishing</small>
  </div>
</div>
                      <div className="modal-footer">
                        <button 
                          type="button" 
                          className="btn btn-secondary" 
                          onClick={() => setShowDatePicker(false)}>
                          Cancel
                        </button>
                        <button 
                          type="button" 
                          className="btn btn-primary" 
                          onClick={handleDateSubmit}>
                          Publish
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <table className="table">
                <thead className="table-dark">
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Date Created</th>
                    <th>Publish Date</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {blogs.length > 0 ? (
                    blogs.map((blog, index) => (
                      <tr key={blog._id}>
                        <td>{index + 1 + (page - 1) * limit}</td>
                        <td className="stye-data">{parse(blog.title)}</td>
                        <td>{new Date(blog.createdAt).toLocaleDateString()}</td>
                        <td>{blog.publishDate ? formatDate(blog.publishDate) : 'Not scheduled'}</td>
                        <td>
                          <span className={`badge ${blog.publish ? 'bg-success' : 'bg-secondary'}`}>
                            {blog.publish ? 'Published' : 'Draft'}
                          </span>
                        </td>
                        <td>
                          <button
                            onClick={() => navigate(`/blogs/${blog._id}`)}
                            className="view-button btn btn-info btn-sm me-1"
                          >
                            View
                          </button>
                          <button
                            onClick={() => navigate(`/update/${blog._id}`)}
                            className="view-button btn btn-warning btn-sm me-1"
                          >
                            Update
                          </button>
                          <button
                            onClick={() => handlePublishClick(blog._id, blog.publish)}
                            className={`view-button btn btn-sm me-1 ${blog.publish ? "btn-danger" : "btn-success"}`}
                          >
                            {blog.publish ? "Unpublish" : "Publish"}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6">No blogs found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-start">
                  <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                  </li>
                  <li className="page-item">
                    <span className="page-link">
                      Page {page} of {totalPages}
                    </span>
                  </li>
                  <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() =>
                        setPage((prev) => (prev < totalPages ? prev + 1 : prev))
                      }
                      disabled={page === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;