import React, { useEffect, useRef, useState } from 'react';
import 'quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageResize from 'quill-resize-image'; // Import the resize module
import LinkModal from './LinkModal'; // Import your modal

Quill.register('modules/resizeImage', ImageResize);

// Add a custom image uploader to handle WebP images
const ImageUploader = function(quill) {
  this.quill = quill;
  
  // Create a hidden file input
  const fileInput = document.createElement('input');
  fileInput.setAttribute('type', 'file');
  fileInput.setAttribute('accept', 'image/jpeg, image/png, image/gif, image/webp'); // Explicitly include WebP
  fileInput.style.display = 'none';
  
  // When a file is selected, handle the upload
  fileInput.addEventListener('change', function() {
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      
      reader.onload = function(e) {
        const range = quill.getSelection(true);
        quill.insertEmbed(range.index, 'image', e.target.result);
      };
      
      reader.readAsDataURL(file);
    }
  });
  
  document.body.appendChild(fileInput);
  
  // Override the original image handler
  const toolbar = quill.getModule('toolbar');
  toolbar.addHandler('image', function() {
    fileInput.click();
  });
};

const Editor = ({ setEditorContent, initialContent }) => {
  const editorRef = useRef(null); // Ref to avoid re-initializing Quill
  const [isModalVisible, setModalVisible] = useState(false);
  const [quill, setQuill] = useState(null); // Store Quill instance

  useEffect(() => {
    if (quill) return; // Prevent re-initialization if quill is already set

    const quillInstance = new Quill(editorRef.current, {
      modules: {
        toolbar: {
          container: '#toolbar-container',
          handlers: {
            link: () => setModalVisible(true),
            color: function (value) {
              if (value) this.quill.format('color', value);
            },
            background: function (value) {
              if (value) this.quill.format('background', value);
            },
            header: function (value) {
              // Save the scroll position before applying the format
              const scrollTop = this.quill.root.scrollTop;

              this.quill.format('header', value === '' ? false : value);

              // Restore the scroll position after applying the header format
              this.quill.root.scrollTop = scrollTop;

              // Focus the editor and set the cursor to the end of the header
              this.quill.focus();
              const range = this.quill.getSelection();
              if (range) {
                this.quill.setSelection(range.index + 1); // Ensure the cursor stays at the correct place
              }
            }
          },
        },
        resizeImage: true, // Enable image resizing
      },
      placeholder: 'Compose an epic...',
      theme: 'snow',
    });

    // Initialize with initial content if provided
    if (initialContent) {
      quillInstance.clipboard.dangerouslyPasteHTML(initialContent);
    }

    quillInstance.on('text-change', () => {
      setEditorContent(quillInstance.root.innerHTML);
    });

    // Register the custom image uploader
    new ImageUploader(quillInstance);

    setQuill(quillInstance);

  }, [quill, initialContent, setEditorContent]);

  const handleLinkSubmit = (url, isNofollow, linkText) => {
    if (url && quill) {
      const range = quill.getSelection();
      
      if (range && range.length > 0) {
        // If text is selected, format it as a link
        quill.format('link', url);
      } else {
        // If no text is selected or at cursor position, insert the link text
        const index = range ? range.index : quill.getLength();
        quill.insertText(index, linkText || url, { link: url });
      }
  
      // Set link attributes after a brief delay to ensure the link element exists
      setTimeout(() => {
        const [linkElement] = quill.container.querySelectorAll(`a[href="${url}"]`);
        if (linkElement) {
          if (isNofollow) {
            linkElement.setAttribute('rel', 'nofollow');
          } else {
            linkElement.removeAttribute('rel');
          }
          linkElement.setAttribute('target', '_blank');
        }
      }, 0);
    }
  };

  // Helper function to handle pasted images including WebP
  useEffect(() => {
    if (!quill) return;
    
    const handlePaste = (event) => {
      const clipboardData = event.clipboardData;
      if (!clipboardData) return;
      
      // Check for images in clipboard
      const items = clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image/') !== -1) {
          const file = items[i].getAsFile();
          if (!file) continue;
          
          const reader = new FileReader();
          reader.onload = (e) => {
            const range = quill.getSelection(true);
            quill.insertEmbed(range.index, 'image', e.target.result);
          };
          reader.readAsDataURL(file);
          
          event.preventDefault();
          break;
        }
      }
    };
    
    quill.root.addEventListener('paste', handlePaste);
    
    return () => {
      quill.root.removeEventListener('paste', handlePaste);
    };
  }, [quill]);

  return (
    <div>
      <div id="toolbar-container">
        {/* Toolbar */}
        <span className="ql-formats">
          <select className="ql-font"></select>
          <select className="ql-size"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-underline"></button>
          <button className="ql-strike"></button>
        </span>
        <span className="ql-formats">
          <input
            type="color"
            className="ql-color-picker"
            onChange={(e) => quill && quill.format('color', e.target.value)}
          />
          <input
            type="color"
            className="ql-background-picker"
            onChange={(e) => quill && quill.format('background', e.target.value)}
          />
        </span>
        <span className="ql-formats">
          <button className="ql-script" value="sub"></button>
          <button className="ql-script" value="super"></button>
        </span>
        <span className="ql-formats">
          <select className="ql-header">
            <option value="">Normal</option>
            <option value="1">Heading 1</option>
            <option value="2">Heading 2</option>
            <option value="3">Heading 3</option>
            <option value="4">Heading 4</option>
            <option value="5">Heading 5</option>
            <option value="6">Heading 6</option>
          </select>
          <button className="ql-blockquote"></button>
          <button className="ql-code-block"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-list" value="ordered"></button>
          <button className="ql-list" value="bullet"></button>
          <button className="ql-indent" value="-1"></button>
          <button className="ql-indent" value="+1"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-direction" value="rtl"></button>
          <select className="ql-align"></select>
        </span>
        <span className="ql-formats">
          <button className="ql-link"></button>
          <button className="ql-image"></button>
          <button className="ql-video"></button>
          <button className="ql-formula"></button>
        </span>
        <span className="ql-formats">
          <button className="ql-clean"></button>
        </span>
      </div>
      <div id="editor" ref={editorRef}></div>
      <LinkModal
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={handleLinkSubmit}
      />
    </div>
  );
};

export default Editor;