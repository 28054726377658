import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css'; 
import Editor from './Editor';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'; 

const UpdateBlog = () => {
  const { id } = useParams(); 
  const navigate = useNavigate(); 

  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState(''); // New state for slug
  const [fullContent, setFullContent] = useState(''); 
  const [headerImage, setHeaderImage] = useState(null);
  const [tags, setTags] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [metaTags, setMetaTags] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isImageValid, setIsImageValid] = useState(false);
  const [categories, setCategories] = useState([]);
  const [altText, setAltText] = useState('');
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [headerImageUrl, setHeaderImageUrl] = useState('');

  const backendURL = process.env.REACT_APP_BackendURL; 

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`${backendURL}/api/blogs/blogs/${id}`);
        if (!response.ok) throw new Error('Failed to fetch blog details');
        const data = await response.json();
  
        setTitle(data.title);
        setSlug(data.slug);
        setFullContent(data.fullContent || '');
        setTags(data.tags);
        setMetaTitle(data.metaTitle);
        setMetaDescription(data.metaDescription);
        setMetaKeywords(data.metaKeywords);
        setMetaTags(data.metaTags);
        setSelectedCategories(data.categories || []);
        setAltText(data.Alt || '');
        
        // Directly set the S3 image URL from your data
        // Check the actual property name in your API response
        if (data.headerImage) {
          setHeaderImageUrl(data.headerImage); // This should be the full S3 URL
          setIsImageValid(true); // Existing image is valid
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
  
    // Rest of your useEffect code...

    const fetchCategories = async () => {
      try {
          const response = await fetch(`${backendURL}/api/getallcategories`);
          const data = await response.json();
          if (response.ok) {
              setCategories(data.categories.map(category => ({
                  value: category.name,
                  label: category.name,
              })));
          } else {
              console.error("Error fetching categories:", data.message);
              toast.error("Error fetching categories");
          }
      } catch (error) {
          console.error("Error fetching categories:", error);
          toast.error("Error fetching categories");
      }
  };
  fetchCategories();

    fetchBlog();
  }, [id]);

  const handleHeaderImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const reader = new FileReader();
      const img = new Image();
  
      reader.onload = (event) => {
        img.onload = () => {
          if (img.width === 530 && img.height === 550) {
            setHeaderImage(file);
            setIsImageValid(true);
            
            // Set a local URL for the selected image to show in the preview
            setHeaderImageUrl(event.target.result);
            
            toast.success('Image dimensions are valid!', { position: "top-right" });
          } else {
            setIsImageValid(false);
            toast.error('Header image dimensions must be 530 x 550', { position: "top-right" });
          }
        };
        img.src = event.target.result;
      };
  
      reader.readAsDataURL(file);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('slug', slug); // Append the slug to the form data
    formData.append('fullContent', fullContent);
    if (headerImage) formData.append('headerImage', headerImage);

    formData.append('tags', tags);
    formData.append('metaTitle', metaTitle);
    formData.append('metaDescription', metaDescription);
    formData.append('metaKeywords', metaKeywords);
    formData.append('metaTags', metaTags);
    formData.append('Alt', altText);

    
    formData.append('categories', JSON.stringify(selectedCategories));

    try {
      const response = await fetch(`${backendURL}/api/blogs/blogs/${id}`, {
        method: 'PUT',
        body: formData,
      });

      if (response.ok) {
        toast.success('Blog Updated successfully!', { position: "top-right" });
        setTimeout(() => {
            navigate(`/dashboard`);
        }, 3000); 
    } else {
        toast.error('Failed to update blog', { position: "top-right" });
    }
} catch (error) {
    console.error('Error:', error);
    toast.error('An error occurred while updating the blog', { position: "top-right" });
}
};

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="add-blog-container">
        <ToastContainer />
      <div className="p-4 pt-5">
        <div className="card shadow">
          <div className="card-header d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Update Blog</h5>
            <a href="# " className="btn btn-primary">All Blogs</a>
          </div>
          <div className="add-blog-container p-4">
            <form onSubmit={handleUpdate} className="row g-3">
              <div className="col-md-6">
                <label className="form-label">Title</label>
                <ReactQuill value={title} onChange={setTitle} theme="snow" required />
              </div>
              <div className="col-md-6">
                <label className="form-label">Slug</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={slug} 
                  onChange={(e) => setSlug(e.target.value)} 
                  required 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Full Content</label>
                <Editor setEditorContent={setFullContent} initialContent={fullContent} />
              </div>
              <div className="col-12">
  <label className="form-label">Header Image</label>
  <input 
    type="file" 
    className="form-control" 
    accept="image/*" 
    onChange={handleHeaderImageChange}
  />
  {headerImageUrl && (
    <div className="mt-2">
      <p>Current image:</p>
      <img 
        src={headerImageUrl} 
        alt="Current header" 
        style={{maxWidth: "200px", maxHeight: "200px"}} 
      />
    </div>
  )}
</div>
              <div className="col-12">
                <label className="form-label">Alt Text (Image Description)</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={altText} 
                  onChange={(e) => setAltText(e.target.value)} 
                  placeholder="Describe the image for accessibility"
                />
              </div>

              <div className="col-12">
                <label className="form-label">Categories</label>
                <Select
                    isMulti
                    options={categories}
                    value={selectedCategories.map(category => ({
                        label: category,
                        value: category
                    }))} 
                    onChange={(selected) => {
                        setSelectedCategories(selected.map(category => category.value));
                    }}
                />
              </div>

              <div className="col-12">
                <label className="form-label">Tags (comma-separated)</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={tags} 
                  onChange={(e) => setTags(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Meta Title</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={metaTitle} 
                  onChange={(e) => setMetaTitle(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Meta Description</label>
                <textarea 
                  className="form-control" 
                  value={metaDescription} 
                  onChange={(e) => setMetaDescription(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Meta Keywords (comma-separated)</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={metaKeywords} 
                  onChange={(e) => setMetaKeywords(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <label className="form-label">Meta Tags (comma-separated)</label>
                <input 
                  type="text" 
                  className="form-control" 
                  value={metaTags} 
                  onChange={(e) => setMetaTags(e.target.value)} 
                />
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary">Update Blog</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateBlog;
