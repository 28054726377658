import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import './AddBlog.css';
import Editor from '../Editor'; // Import your custom Editor component
import ReactQuill from 'react-quill'; // Import React Quill
import 'react-quill/dist/quill.snow.css'; // Import styles for React Quill
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'; 

const AddBlog = () => {

    const [title, setTitle] = useState('');
    const [slug, setSlug] = useState(''); // Added slug field
    const [fullContent, setFullContent] = useState(''); // This will be set by the Editor
    const [headerImage, setHeaderImage] = useState(null);
    const [tags, setTags] = useState('');
    const [metaTitle, setMetaTitle] = useState('');
    const [metaDescription, setMetaDescription] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [metaTags, setMetaTags] = useState('');
    const [categories, setCategories] = useState([]);
    const [Alt, setAlt] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);

    const navigate = useNavigate();
    const [isImageValid, setIsImageValid] = useState(false);
    const backendURL = process.env.REACT_APP_BackendURL; 

    const handleHeaderImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
            const img = new Image();

            reader.onload = (event) => {
                img.onload = () => {
                    if (img.width === 530 && img.height === 550) {
                        setHeaderImage(file);
                        setIsImageValid(true);
                        toast.success('Image dimensions are valid!', { position: "top-right" });
                    } else {
                        setIsImageValid(false);
                        toast.error('Header image dimensions must be 530 x 550', { position: "top-right" });
                    }
                };
                img.src = event.target.result;
            };

            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Check if title is empty (for ReactQuill)
        if (!title || title.trim() === '') {
            toast.error('Title is required!', { position: "top-right" });
            return;
        }
    
       
        const formData = new FormData();
        formData.append('title', title);
        formData.append('slug', slug); // Add slug to form data
        formData.append('fullContent', fullContent);
        formData.append('metaTitle', metaTitle);
        formData.append('metaDescription', metaDescription);
        formData.append('metaKeywords', metaKeywords);
        formData.append('metaTags', metaTags);
        formData.append('tags', tags);
        formData.append('headerImage', headerImage);
        formData.append('Alt', Alt);    
        const selectedCategoryLabels = selectedCategories.map(category => category.label);
        formData.append('categories', JSON.stringify(selectedCategoryLabels));
    
        try {
            const response = await fetch(`${backendURL}/api/blogs/add-blog`, {
                method: 'POST',
                body: formData,
            });
    
            const contentType = response.headers.get("content-type");
    
            let errorMessage = "Failed to add blog";
    
            if (contentType && contentType.includes("application/json")) {
                const result = await response.json();
                errorMessage = result.message || result.error || JSON.stringify(result);
            } else {
                errorMessage = await response.text();
            }
    
            if (response.ok) {
                toast.success('Blog added successfully!', { position: "top-right" });
                setTimeout(() => {
                    navigate(`/dashboard`);
                }, 3000);
            } else {
                toast.error(errorMessage, { position: "top-right" });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(`An error occurred: ${error.message}`, { position: "top-right" });
        }
    };
    
    
    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(`${backendURL}/api/getallcategories`);
                const data = await response.json();
                if (response.ok) {
                    setCategories(data.categories.map(category => ({
                        value: category._id, 
                        label: category.name 
                    })));
                } else {
                    console.error("Error fetching categories:", data.message);
                    toast.error("Error fetching categories");
                }
            } catch (error) {
                console.error("Error fetching categories:", error);
                toast.error("Error fetching categories");
            }
        };
        fetchCategories();
    }, []);

    return (
        <div>
            <div >
            <ToastContainer />
                <div className='p-4 pt-5'>
                    <div className="card shadow">
                        <div class="card-header d-flex justify-content-between align-items-center">
                            <h5 class="mb-0">Create</h5>
                            <a href="# " class="btn btn-primary">All Blog</a>
                        </div>
                        <div className="add-blog-container p-4">
                            <div className="main-content">
                                <form onSubmit={handleSubmit} className="row g-3">
                                    <div className="col-md-6">
                                        <label for="inputEmail4" className="form-label">Title</label>
                                        <ReactQuill value={title} onChange={setTitle} theme="snow" />
                                    </div>
                                    
                                    <div className="col-md-6">
                                        <label for="inputAddress" className="form-label">Slug</label>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            value={slug}
                                            onChange={(e) => setSlug(e.target.value)} // Handle slug input change
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Content: </label>
                                        <Editor setEditorContent={setFullContent} />
                                    </div>
                                    <div className="col-12">
                            <label className="form-label">Categories</label>
                            <Select
                                isMulti
                                options={categories} 
                                value={selectedCategories}
                                onChange={setSelectedCategories} 
                                placeholder="Select Category"
                            />
                        </div>

                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Tags: </label>
                                        <input type="text"
                                            className="form-control"
                                            value={tags}
                                            onChange={(e) => setTags(e.target.value)} />
                                    </div>
                                    <div className="col-12">
                                        <label for="formFile" className="form-label">Header Image : </label>
                                        <input className="form-control" type="file" onChange={handleHeaderImageChange} />
                                    </div>
                                    <div className="col-12">
    <label for="altText" className="form-label">Alt Text for Header Image:</label>
    <input
        type="text"
        className="form-control"
        value={Alt}
        onChange={(e) => setAlt(e.target.value)} // Handle alt input change
    />
</div>
                                    <hr></hr>
                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Meta Title </label>
                                        <input type="text"
                                            className="form-control"
                                            value={metaTitle}
                                            onChange={(e) => setMetaTitle(e.target.value)}
                                             />
                                    </div>

                                    <div className="col-12">
                                        <label for="inputAddress" className="form-label">Meta Description :</label>
                                        <textarea type="textarea" className="form-control" value={metaDescription}
                                            onChange={(e) => setMetaDescription(e.target.value)}
                                             />
                                    </div>

                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Meta Keywords (comma-separated): </label>
                                        <input type="text"
                                            className="form-control"
                                            value={metaKeywords}
                                            onChange={(e) => setMetaKeywords(e.target.value)} />
                                    </div>

                                    <div className="col-12">
                                        <label for="inputAddress2" className="form-label">Meta Tags (comma-separated): </label>
                                        <input type="text"
                                            className="form-control"
                                            value={metaTags}
                                            onChange={(e) => setMetaTags(e.target.value)} />
                                    </div>

                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary">Add</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddBlog;
