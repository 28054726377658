import React, { useEffect, useState } from 'react';

const Lead = () => {
  const backendURL = process.env.REACT_APP_BackendURL;

  const [messages, setMessages] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  const fetchSubscribers = async () => {
    try {
      const response = await fetch(`${backendURL}/api/subscribe`);
      const data = await response.json();
      setSubscribers(data);
      console.log("Subscribers:", data);
    } catch (error) {
      console.error("Error fetching subscribers:", error);
    }
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await fetch(`${backendURL}/api/contact/messages`);
        const data = await response.json();
        
        // Ensure we are extracting the messages array from the response
        if (data.success && Array.isArray(data.messages)) {
          setMessages(data.messages);  // Set the messages array directly
        } else {
          console.error('API returned unexpected data format:', data);
        }
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };    
    
    fetchSubscribers();
    fetchMessages();
  }, []);

  const filteredMessages = messages.filter((message) =>
    message.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
    message.phone.includes(searchTerm)
  );

  const totalPages = Math.ceil(filteredMessages.length / itemsPerPage);
  const indexOfLastMessage = page * itemsPerPage;
  const indexOfFirstMessage = indexOfLastMessage - itemsPerPage;
  const currentMessages = filteredMessages.slice(indexOfFirstMessage, indexOfLastMessage);

  const openModal = (person) => {
    setSelectedPerson(person);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedPerson(null);
    setIsModalOpen(false);
  };

  // Function to truncate the message text
  const truncateMessage = (message, maxLength = 50) => {
    if (message.length > maxLength) {
      return message.substring(0, maxLength) + '...';
    }
    return message;
  };

  return (
    <div>
      <div className="text-center">
        <div className='p-4 pt-5'>
          <div className="card shadow">
            <div className="card-header d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Leads</h5>
            </div>
            <div className="card-body">
              <div className="row mb-3">
                <div className="col-4">
                  <input 
                    className="form-control me-2" 
                    type="text" 
                    aria-label="Search"
                    placeholder="Search by email, or phone..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} 
                  />
                </div>
              </div>

              <table className="table table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Message</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentMessages.map((message, index) => (
                    <tr 
                      key={message._id} 
                      onClick={() => openModal(message)} 
                      style={{ cursor: 'pointer', transition: 'background 0.2s ease-in-out' }}
                      className="table-row-hover"
                    >
                      <td>{index + 1}</td>
                      <td>{message.firstName} {message.lastName}</td>
                      <td>{message.email}</td>
                      <td>{message.phone}</td>
                      <td>{truncateMessage(message.message)}</td> {/* Apply truncation here */}
                      <td>{new Date(message.createdAt).toLocaleDateString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-start">
                  <li className={`page-item ${page === 1 ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                      disabled={page === 1}
                    >
                      Previous
                    </button>
                  </li>
                  <li className="page-item disabled">Page {page} of {totalPages}</li>
                  <li className={`page-item ${page === totalPages ? 'disabled' : ''}`}>
                    <button
                      className="page-link"
                      onClick={() => setPage((prev) => Math.min(prev + 1, totalPages))}
                      disabled={page === totalPages}
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {isModalOpen && selectedPerson && (
        <div className="modal show d-block" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Person Details</h5>
                <button type="button" className="btn-close" onClick={closeModal}></button>
              </div>
              <div className="modal-body" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                <p><strong>Name:</strong> {selectedPerson.firstName} {selectedPerson.lastName}</p>
                <p><strong>Email:</strong> {selectedPerson.email}</p>
                <p><strong>Phone:</strong> {selectedPerson.phone}</p>
                <p><strong>Message:</strong> {selectedPerson.message}</p> {/* Full message here */}
                <p><strong>State:</strong> {selectedPerson.state}</p>
                <p><strong>Selected Services:</strong> {selectedPerson.selectedServices.join(', ')}</p>
                <p><strong>Plan Type:</strong> {selectedPerson.planType}</p>
                <p><strong>Practice Type:</strong> {selectedPerson.practiceType}</p>
                <p><strong>Website:</strong> {selectedPerson.website ? (
                  <a href={selectedPerson.website} target="_blank" rel="noopener noreferrer">
                    {selectedPerson.website}
                  </a>
                ) : 'N/A'}</p>
                <p><strong>Consent Given:</strong> {selectedPerson.consent ? 'Yes' : 'No'}</p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Lead;
